/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react';
import {Fragment, MutableRefObject, useEffect, useRef, useState} from 'react';
import {useData} from '../hooks/useData';
import {Skeleton} from '../components/Skeleton';
import {userpageCSS} from './ViewProfile.styles';
import {useMounting} from '../hooks/useMounting';
import {WidgetChildren} from '../types';

type MetaLocaleElement = HTMLMetaElement & {
  setLocale: (value: string) => void;
};

export const ViewProfile = () => {
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const {user, isLoading} = useData();
  const {mountChildren, getWidgetProps} = useMounting();
  const [passwordConfig, setPasswordConfig] = useState<WidgetChildren | undefined>();
  const [entityLocale, setEntityLocale] = useState<string | null>(null);
  const localeEl = useRef<MetaLocaleElement>(document.getElementById('locale') as MetaLocaleElement);

  useEffect(() => {
    const localeNameAttr = localeEl.current.getAttribute('locale-name');
    setEntityLocale(localeNameAttr);
  }, []);

  useEffect(() => {
    if (!user.id || !passwordConfig || isLoading) return;
    const props = {
      ...passwordConfig,
      props: {
        ...passwordConfig.props,
        userId: user.id,
        userEnabled: user.enabled
      }
    };

    if (!mountingPointRef.current) return;

    const unmount = mountChildren(mountingPointRef.current, [props]);
    return () => {
      unmount();
    };
  }, [user, isLoading, passwordConfig]);

  useEffect(() => {
    setPasswordConfig(getWidgetProps('widget-vue-change-password', '@netvision/widget-vue-change-password'));
  }, [getWidgetProps]);
  
  return (
    <div className="userpage" css={userpageCSS}>
      <div className="userpage__header">
        <div className="userpage__header-main">
          {isLoading ? (
            <div className="userpage__header-preloader">
              <Skeleton />
            </div>
          ) : (
            <Fragment>
              <div className="userpage__header-login">
                <label>Логин</label>
                <h2>{user.username}</h2>
              </div>
              <div ref={mountingPointRef} />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
