import React, {useEffect, useMemo, useState} from 'react';
import {PublishPayload} from '@netvision/lib-types-frontend';
import {useObserver} from '../hooks/useEventBus';
import {ViewLoading} from '../views/ViewLoading';
import {ViewProfile} from '../views/ViewProfile';

interface MainProps {
  initView: string;
}

export const Main = ({initView}: MainProps) => {
  const {eventBus, eventBusID} = useObserver();
  const [currentView, setCurrentView] = useState<string>(initView);

  const TabViewsMap = new Map([
    ['profile', <ViewProfile />]
  ]);

  function switchTab<T = string>(payload: PublishPayload<T>) {
    setCurrentView(payload.data as string);
  }

  const ViewComponent = useMemo(
    () => (currentView && TabViewsMap.has(currentView) ? TabViewsMap.get(currentView) : <ViewLoading />),
    [currentView]
  );

  useEffect(() => {
    // @ts-ignore
    if (!eventBus.subscribersMap.get(eventBusID)?.size) {
      eventBus.subscribe(eventBusID, 'switchTab', switchTab);
    }
  }, [eventBus]);

  return <div className="userpage">{ViewComponent}</div>;
};
